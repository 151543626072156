'use strict';

import {PRINT_QUEUES} from '@utils/endpoints.js';
import HttpClient from './http-client';

class PrinterQueueService {
    constructor() {
        this.uri = PRINT_QUEUES;
    }

    async sendToPrintProductionTaskLabel(params) {
        return await HttpClient.post(`${PRINT_QUEUES}/production-task`, params);
    }

    async sendToPrintPackLabel(params) {
        return await HttpClient.post(`${PRINT_QUEUES}/pack-label`, params);
    }
}

export default new PrinterQueueService();
