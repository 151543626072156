import forEach from 'lodash/forEach';

let instance = null;

/**
 * Реализует обработчик ошибок (singleton)
 */
class ErrorsBag {
    /**
     * Корзина ошибок
     *
     * @type {Array}
     */
    errors = [];

    /**
     * Возвращаем объект обработчика ошибок
     *
     * @return {*}
     */
    constructor() {
        if ( !instance ) {
            this.discard();
            instance = this;
        }

        return instance;
    }

    /**
     * Получаем корзину с ошибками
     *
     * @return {Array}
     */
    list() {
        return this.errors;
    }

    /**
     * Получаем состояние обработчика ошибок
     *
     * @return {boolean}
     */
    state() {
        return this.errors.length > 0;
    }

    /**
     * Сбрасываем обработчик ошибок
     */
    discard() {
        this.errors = [];
    }

    /**
     * Добавляем ошибку в корзину
     *
     * @param error
     */
    add( error ) {
        this.errors.push( error );
    }

    /**
     * Заполняем корзину ошибок скопом
     *
     * @param bug
     * @param append
     */
    fill( bug, append = true ) {
        if ( append !== true ) {
            this.discard();
        }

        if ( bug instanceof String || typeof bug === 'string') {
            this.add( bug );

            return this;
        }

        if ( bug instanceof Array ) {
            forEach( bug, error => this.add( error ) );

            return this;
        }

        if ( bug instanceof Object && bug.errors ) {
            if ( bug.errors instanceof Array ) {
                forEach( bug.errors, error => this.add( error ) );

                return this;
            }

            if ( bug.errors instanceof Object ) {
                forEach(
                    bug.errors,
                    source => forEach( source, error => this.add( error ) )
                );

                return this;
            }
        }

        this.add( 'Неизвестная ошибка, см. подробности в консоле.' );
        console.log( 'unknown error: ' + JSON.stringify( bug ) );

        return this;
    }
}

export default new ErrorsBag();
